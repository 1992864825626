<template>
 <div>
  <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true"> 
    <Splitter style="border:none;" >
      <SplitterPanel :minSize="50" >
        <div class="order-status">
          <div v-tooltip.right="'구매자님의 주문이 확인되었고, 해외 판매자에게 구매요청해놓은 상태입니다.'">주문접수
          </div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(0, val0)" class="link">{{ val0 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(0, val0)" class="link">{{ val0 }}</a> 건
            </span>
          </span>
        </div>
        <div class="order-status">
          <div v-tooltip.right="'구매자님의 결제 내역이 확인된 상태입니다. 곧 해외 판매자에게서 상품을 구매합니다.'">입금완료</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(2, val2)" class="link">{{ val2 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(2, val2)" class="link">{{ val2 }}</a> 건
            </span>
          </span>
        </div>
        <div class="order-status">
          <div v-tooltip.right="'해외 판매자가 상품을 배송 보냈지만 아직 해당 상품이 GEEK9 창고에 도착하기 전 상태입니다.'">입고대기</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(4, val4)" class="link">{{ val4 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(4, val4)" class="link">{{ val4 }}</a> 건
            </span>
          </span>
        </div>
        <div class="order-status">
          <div v-tooltip.right="'상품이 한국으로 배송 중입니다. 한국 세관을 통과해야 그때부터 송장번호로 조회가 됩니다.'">배송중</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(6, val6)" class="link">{{ val6 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(6, val6)" class="link">{{ val6 }}</a> 건
            </span>
          </span>
        </div>
        <div class="order-status">
          <div v-tooltip.right="'해외 판매자 혹은 구매자님의 요청으로 거래가 취소된 상태입니다. 해외 결제라서 환불까지 최소 한 달의 시간이 필요합니다.'">거래취소</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(-1, val8)" class="link">{{ val8 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(-1, val8)" class="link">{{ val8 }}</a> 건
            </span>
          </span>
        </div>
      </SplitterPanel>
      <SplitterPanel class="flex items-center justify-center" :minSize="50" >
        <div class="order-status">
          <div v-tooltip.right="'구매자님께서 결제를 해주시면 해외 판매자에게 물건을 구매할 수 있는 상태입니다.'">입금대기</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(1, val1)" class="link">{{ val1 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(1, val1)" class="link">{{ val1 }}</a> 건
            </span>
          </span>
        </div> 
        <div class="order-status">
          <div v-tooltip.right="'해외 판매자에게서 상품 구매가 완료된 상태입니다. 해외 판매자가 상품을 보낼 때까지 기다려 주시기 바랍니다.'">구매완료</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(3, val3)" class="link">{{ val3 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(3, val3)" class="link">{{ val3 }}</a> 건
            </span>
          </span>
        </div> 
        <div class="order-status">
          <div v-tooltip.right="'판매자가 보낸 상품이 GEEK9 창고에 도착한 상태입니다. 한국으로 보내는 배송비 안내를 곧 받으실 수 있습니다. 조금만 더 기다려주세요!'">창고도착</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(5, val5)" class="link">{{ val5 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(5, val5)" class="link">{{ val5 }}</a> 건
            </span>
          </span>
        </div> 
        <div class="order-status">
          <div v-tooltip.right="'구매자님께서 상품을 정상적으로 수령한 상태입니다.'">거래완료</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(7, val7)" class="link">{{ val7 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(7, val7)" class="link">{{ val7 }}</a> 건
            </span>
          </span>
        </div> 
        <div class="order-status">
          <div v-tooltip.right="'구매자님의 계좌로 환불이 완료된 상태입니다.'">환불완료</div>
          <span class="order-count">
            <span v-if="this.api.getCookies('page') === 'list'">
              <a @click="setOrder(-2, val9)" class="link">{{ val9 }}</a> 건
            </span>
            <span v-else>
              <a @click="phref(-2, val9)" class="link">{{ val9 }}</a> 건
            </span>
          </span>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
  <login v-else @login="login" :border=true /> 
 </div>
</template>

<script>

import router from '../../router'
import login from '@/components/common/Login.vue'

export default {
  emits: ["login"],
  components: {
    login
  },
  setup() {
    return {
    }
  },
  mounted() {
    this.emitter.on('distinct' + this.page, this.setOrder)
    this.emitter.on('refresh' + this.page, this.getOrderInfo)
  },
  unmounted() {
    this.emitter.off('distinct' + this.page, this.setOrder)
    this.emitter.off('refresh' + this.page, this.getOrderInfo)
  },
  props: {
    page:String
  },
  data() { 
    return { 
      range: 'r2',
      val0:'', val1:'', val2:'', val3:'', val4:'', val5:'', val6:'', val7:'', val8:'', val9:'',
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    async getOrderInfo(param) {
      const id = this.api.getCookies('id')
      if(id) {
        this.emitter.emit('dialog', {flag:true})
        this.range = param.range
        const rangeResult = this.api.getRange(this.range)
        await this.api.axiosGql('SELECT_ORDER_STATUS_CNT', {type:'SELECT_ORDER_STATUS_CNT', id: ""+id, startdate: rangeResult.startDate, enddate: rangeResult.endDate})
        .then( async (res) => {
          const json = res.data
          // 주문접수(0)/입금대기(1)/입금완료(2)/구매완료(3)/입고대기(4)/창고도착(5)/배송중(6)/거래완료(7)/거래취소(8)/환불완료(9)
          this.val0 = json.ORDER_CHECK.length
          this.val1 = json.DEPOSIT_READY.length
          this.val2 = json.DEPOSIT_CMPL.length
          this.val3 = json.PURCHASE_CMPL.length
          this.val4 = json.WAREHOUSING.length
          this.val5 = json.DELIVERY_READY.length
          this.val6 = json.DELIVERY_ING.length
          this.val7 = json.TRANSACTION_CMPL.length
          this.val8 = json.TRANSACTION_CNCL.length
          this.val9 = json.REFUND_CMPL.length
        })
        .catch( (err) => {
          console.log(err)
        })
        .finally(()=> {
          this.emitter.emit('dialog', {flag:false})
        })
      }
    },
    async setOrder(status, cnt) {
      if(cnt !== 0) {
        this.emitter.emit('dialog', {flag:true})
        let rangeJson = this.api.getRange(this.range)
        await this.api.axiosGql('SELECT_DISTINCT_ORDER_LIST', {type:'SELECT_DISTINCT_ORDER_LIST', id: "" + this.api.getCookies('id'), startdate: rangeJson.startDate, enddate: rangeJson.endDate, status: status, page: 1})
        .then( async (res) => {
          const json = res.data
          for(var i = 0; i < json.length; i++) {
            let order = json[i]
            let country = order.COUNTRY
            let currency = ' EUR'
            if(country === '홍콩') currency = ' HKD'
            else if(country === '영국') currency = ' 파운드'
            else if(country === '미국') currency = ' USD'
            else if(country === '일본') currency = ' 엔'
            else if(country === '중국') currency = ' 위안화'
            else if(country === '싱가포르') currency = ' SGD'
            else if(country ==='호주') currency = ' AUD'
            else if(country ==='뉴질랜드') currency = ' NZD'
            else if(country ==='캐나다') currency = ' CAD'
            else if(country ==='대만') currency = ' TWD'
            // else if(country ==='태국') currency = ' THB'
            // else if(country ==='덴마크') currency = ' DKK'
            // else if(country ==='베트남') currency = ' VND'
            else if(country === '기타') currency = ''
            
            order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency
            let tmpStatus = order.STATUS

            if(tmpStatus !== undefined) order.STATUS = this.api.getOrderStatus(tmpStatus)

            let invoiceNumber = order.INVOICE_NUMBER
            let deliveryKrCompany = order.DELIVERY_KR_COMPANY

            if(invoiceNumber !== undefined && deliveryKrCompany !== undefined && (tmpStatus === 6 || tmpStatus === 7) ) order.INVOICE_NUMBER = this.api.getDeliveryUrl(invoiceNumber, deliveryKrCompany)
          }
          this.$emit("setOrder", res.data, status)
        })
        .catch( (err) => {
          console.log(err)
        })
        .finally( ()=> {
          this.emitter.emit('dialog', {flag:false})
        })
      } else {
        this.$notify(this.api.getOrderStatus(status) + ' 내역이 없습니다.')
      }
    },
    async phref(type, cnt) {
      if(cnt === 0) {
        this.$notify(this.api.getOrderStatus(type) + ' 내역이 없습니다.')
      } else {
        this.emitter.emit('dialog', {flag:true})
        let rangeJson = this.api.getRange(this.range)
        // IDX, STATUS, NAME, COUNTRY, URL, IMG_URL, SELL_PRICE, ORIGIN_PRICE, ORDER_DATE, TRACKING_NUMBER, INVOICE_NUMBER, DELIVERY_KR_COMPANY
        await this.api.axiosGql('SELECT_DISTINCT_ORDER_LIST', {type:'SELECT_DISTINCT_ORDER_LIST', id: "" + this.api.getCookies('id'), startdate: rangeJson.startDate, enddate: rangeJson.endDate, status: type, page: 1})
        .then( async (res) => {
          let orderArray = new Array();
          res.data.map(order => {
            var country = order.COUNTRY
            var currency = ' EUR'
            if(country === '홍콩') currency = ' HKD'
            else if(country === '영국') currency = ' 파운드'
            else if(country === '미국') currency = ' USD'
            else if(country === '일본') currency = ' 엔'
            else if(country === '중국') currency = ' 위안화'
            else if(country === '싱가포르') currency = ' SGD'
            else if(country ==='호주') currency = ' AUD'
            else if(country ==='뉴질랜드') currency = ' NZD'
            else if(country ==='캐나다') currency = ' CAD'
            else if(country ==='대만') currency = ' TWD'
            // else if(country ==='태국') currency = ' THB'
            // else if(country ==='덴마크') currency = ' DKK'
            // else if(country ==='베트남') currency = ' VND'
            else if(country === '기타') currency = ''
            
            order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency

            order.range = this.range
            orderArray.push(JSON.stringify(order))
          })
          router.push({name:"list", params: orderArray})
        })
        .catch( (err) => {
          console.log(err)
        }) 
        .finally( ()=> {
          this.emitter.emit('dialog', {flag:false})
        })
      }
    }    
  }
}
</script>

<style scoped>
.order-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.order-count {
  margin-left: auto;
}
.order-count > span > a {
  color:red;
  font-weight: bold;
}
</style>